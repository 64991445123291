import { defineStore } from 'pinia';
import {
  PAYER_PLAN_OPTIONS,
  PHASE_STATUS_REASON_OPTIONS,
  BILLING_ENTITY_OPTIONS,
  FINANCIAL_CLASS_OPTIONS,
  BILLING_PROVIDER_OPTIONS,
  USER_OPTIONS,
  LOCATION_OPTIONS,
  DEPARTMENT_OPTIONS,
  BALANCE_BUCKET_OPTIONS,
  AGE_CATEGORY_OPTIONS,
  LAST_ACTIVITY_TYPE_OPTIONS,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
  FAKE_AR_DATA,
  fetchAndSortARData,
} from '@/utils/ogFakeData';

export const useArStatsStore = defineStore('arStats', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'rsn',
    totalRows: 0,

    fakeARData: [],
    OGFakeActivityData: FAKE_AR_DATA,

    startDate: '04/01/2024',
    endDate: '10/31/2024',
    lastUpdated: '10/31/2024',

    currentARCount: 1218,
    currentARBalance: 610796,
    percentGreaterThan90: 100,
    percentGreaterThan180: 100,
    percentGreaterThan365: 93,

    highDollarCount: 25,
    highDollarAmount: 9166967,
    highDollarPercentage: 4,

    currentMonthTrendTotal: 168886578,
    dateRangeAverage: 652294123,
    monthVsSixMonthAverage: 5221400,
    percentDiffCurrency: 12.2,
    monthVsSixMonthAverageAgain: 2168,
    percentDiff: 1.4,

    //For modal
    filtersApplied: [],

    billingProviderOptions: BILLING_PROVIDER_OPTIONS,
    billingEntityOptions: BILLING_ENTITY_OPTIONS,
    userDropdownOptions: USER_OPTIONS,
    locationOptions: LOCATION_OPTIONS,
    departmentOptions: DEPARTMENT_OPTIONS,
    financialClassOptions: FINANCIAL_CLASS_OPTIONS,
    payerPlanDDOptions: PAYER_PLAN_OPTIONS,
    phaseStatusReasonOptions: PHASE_STATUS_REASON_OPTIONS,
    balanceBucketOptions: BALANCE_BUCKET_OPTIONS,
    ageCategoryOptions: AGE_CATEGORY_OPTIONS,
    lastActivityTypeCodeOptions: LAST_ACTIVITY_TYPE_OPTIONS,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
  },
  actions: {
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [{ name: 'Date Range Applied' }];
    },
    resetFilters() {
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    async fetchAndSortARStatsData(page = 1, direction = 'asc', field = 'user') {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortARData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeARData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
  },
});
